<template>
  <div class="member-shop-product-table">
    <BaseTable :data="displayData">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn prop="type" label="性質" align="center" />
      <BaseElTableColumn prop="name" label="名稱" align="center" />
      <BaseElTableColumn prop="isPublic" label="公開顯示" align="center">
        <template slot-scope="scope">
          <Tag :type="scope.row.isPublic.type">
            {{ scope.row.isPublic.label }}
          </Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn prop="price" label="預設售價" align="center" />
      <BaseElTableColumn prop="order" label="排序" align="center" />
      <BaseElTableColumn label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            @edit="onRowEdit(scope.row)"
            @delete="onRowDelete(scope.row)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <DeleteDialog
      v-if="modal.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="modal.delete = false"
      @delete="deleteRow"
    />
  </div>
</template>

<script>
import { defineComponent, computed, reactive, ref } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import TableEditBtnGroup from '@/components/Button/TableEditBtnGroup.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog.vue'
import Tag from '@/components/Tag/Tag.vue'
import { useRouter } from 'vue-router/composables'
import { DeleteMemberShopProduct } from '@/api/memberShop'
import { productTypeConfig } from '@/config/memberShop'
import store from '@/store'
import { get } from 'lodash'

export default defineComponent({
  name: 'MemberShopProductTable',
  components: { EmptyBlock, TableEditBtnGroup, DeleteDialog, Tag },
  props: {
    data: { type: Array, default: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const router = useRouter()
    const shopId = computed(() => store.getters.shop)
    const selectRow = ref(null)
    const displayData = computed(() => {
      return props.data.map(i => ({
        type: get(productTypeConfig[i.type], 'label'),
        name: i.name,
        isPublic: i.isPublic ? { label: '是', type: 'action' } : { label: '否', type: 'info' },
        price: i.price,
        order: i.order,
        id: i.id,
      }))
    })
    const modal = reactive({
      delete: false,
    })

    const onRowEdit = (row) => {
      router.push({
        name: 'MemberShopProductEdit',
        params: { id: row.id },
      })
    }
    const onRowDelete = async (row) => {
      selectRow.value = row
      modal.delete = true
    }
    const deleteRow = async () => {
      const [, err] = await DeleteMemberShopProduct({ shopId: shopId.value, id: selectRow.value.id })
      if (err) return window.$message.error(err)
      window.$message.success('刪除成功！')
      modal.delete = false
      emit('refresh')
    }
    return {
      productTypeConfig,
      displayData,
      onRowEdit,
      onRowDelete,
      deleteRow,
      modal,
    }
  },
})
</script>

<style scoped lang="postcss">

</style>
