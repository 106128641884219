<template>
  <main class="member-shop-product-block">
    <FiltersContainer>
      <BaseElInput
        v-model="search.name"
        clearable
        placeholder="輸入商品名稱"
        @keypress.enter.native="refresh(true)"
        @clear="refresh(true)"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </BaseElInput>
      <BaseElSelect
        v-model="search.type"
        clearable
        placeholder="選擇商品性質"
        @clear="refresh(true)"
        @change="refresh(true)"
      >
        <BaseElSelectOption v-for="type in filteredProductType" :key="type.value" :value="type.value" :label="type.label" />
      </BaseElSelect>
    </FiltersContainer>
    <PageTitle btn="新增" @btnClick="$router.push({name: 'MemberShopProductEdit'})" />

    <MemberShopProductTable v-loading="loading.table" :data="tableData" @refresh="refresh" />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
  </main>
</template>

<script>
import { defineComponent, reactive, computed, onMounted, onActivated } from 'vue'
import MemberShopProductTable from './MemberShopProductTable.vue'
import { GetMemberShopProduct, GetMemberShopProductCount } from '@/api/memberShop'
import { useTable } from '@/use/table'
import { productTypeConfig } from '@/config/memberShop'
import store from '@/store'
import { get, omit } from 'lodash'

export default defineComponent({
  name: 'MemberShopProductBlock',
  components: { MemberShopProductTable },
  setup () {
    const { tableData, tableDataCount, tableOptions, pageStartIndex, loading } = useTable()
    const shopId = computed(() => store.getters.shop)
    const search = reactive({
      name: null,
      type: null,
    })

    const getMemberShopProduct = async (payload) => {
      const [res, err] = await GetMemberShopProduct(payload)
      if (err) return window.$message.error(err)
      tableData.value = res
    }
    const getMemberShopProductCount = async (payload) => {
      const [res, err] = await GetMemberShopProductCount(payload)
      if (err) return window.$message.error(err)
      tableDataCount.value = res.count
    }
    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        name: search.name || undefined,
        type: search.type || undefined,
      }
      await Promise.allSettled([
        getMemberShopProduct(payload),
        getMemberShopProductCount(payload),
      ])
    }
    const filteredProductType = computed(() => {
      return omit(productTypeConfig, ['cashback', 'coupon'])
    })
    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onMounted(async () => {
      await refresh()
    })
    onActivated(() => {
      refresh()
    })

    return {
      get,
      search,
      refresh,
      tableData,
      tableDataCount,
      tableOptions,
      loading,
      productTypeConfig,
      filteredProductType,
    }
  },
})
</script>

<style scoped lang="postcss">

</style>
